export function validate() {

    // Get the user's preferred motion settings
    const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    document.querySelectorAll('form.validate, form.needs-validation').forEach((form) => {

        form.addEventListener('submit', function(e) {

            // Don't automatically post data on submit
            e.preventDefault();

            // Declare Variables
            const feedback = form.querySelector('.feedback');
            const button = form.querySelector('button[type="submit"]');
            const url = form.getAttribute('action');
            const data = new FormData(this);
            const alert_message = feedback.querySelector('.alert');
            const language = document.querySelector('html').getAttribute('lang');
            let html = '';
            const buttonValue = button.innerHTML;

            // Get all languages
            let ButtonLoadingValue;
            let warningMessage;
            let errorMessage;
            switch (language) {
                case 'en_GB':
                    ButtonLoadingValue = 'Loading';
                    warningMessage = 'Could not send form';
                    errorMessage = 'An unspecified error has occurred while processing your request. Please try again.'
                    break;
                case 'nl_NL':
                    ButtonLoadingValue = 'Laden';
                    warningMessage = 'Probleem met versturen';
                    errorMessage = 'Er heeft zich een onverwachts probleem voorgedaan bij het verwerken van uw verzoek. Probeer het nog eens.'
                    break;
            }

            // Return error if Honeypot input field is missing
            if (form.querySelector('input[name="gender"]') === null) {
                alert('Honeypot field is missing...');
                return false;
            }

            // Return error if feedback container div is missing
            if (feedback === false) {
                alert('<div> with class ".feedback" is missing...');
                return false;
            }

            // Change button to disabled when form is busy processing the data based on language
            button.innerHTML = ButtonLoadingValue;
            button.setAttribute('disabled', 'disabled');
            button.innerHTML += '<span class="icon-basic icon-spinner animate-spin" aria-hidden="true"></span>';

            if (alert_message !== null) {
                feedback.style.display = 'none';
                feedback.removeChild(alert_message);
            }

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response) {
                console.log(response);
                let status = response.data.status;
                let warnings = response.data.warnings;
                let hasError = response.data.hasError;
                let message = response.data.message;

                // Skip following steps and go to new page if a redirect is called
                if (response.data.redirect) {
                    window.location = response.data.redirect;
                    return false;
                }

                // Otherwise, indicate that the form has been validated by the client (add the 'required' attribute for fields that must undergo client-side validation)
                form.classList.add('was-validated');

                // If an error occurs, populate the alert with negative feedback
                if (status === 'error') {

                    if (!Array.isArray(warnings)) {
                        warnings = [];
                    }

                    html += '<div class="alert alert--error alert-dismissible" role="alert"><button type="button" class="btn-close alert__dismiss" aria-label="Close"></button><div class="alert__heading"><span class="icon-basic icon-attention-triangle" aria-hidden="true"></span><strong>' + warningMessage + '</strong></div><hr><ul>';

                    // Show all the warnings
                    if (warnings.length > 0) {
                        warnings.forEach(function callback(value, index) {
                            html += '<li><span class="feedback-icon icon-basic icon-cancel-circled" aria-hidden="true"></span>' + value + '</li>';
                            let errorFields = form.querySelectorAll('[name="' + hasError[index] + '"]');
                            for (const errorField of errorFields) {
                                errorField.classList.add('has-error');
                            }
                        });
                    } else {
                        html += '<li>' + message + '</li>';
                    }

                    html += '</ul></div>';

                    if (typeof grecaptcha !== 'undefined' && grecaptcha) {
                        grecaptcha.reset();
                    }

                }

                // If the form is sent successfully, populate the alert with positive feedback
                if (status === 'success') {
                    html += '<div class="alert alert--success alert-dismissible" role="alert"><button type="button" class="btn-close alert__dismiss" aria-label="Close"></button><div class="alert__heading"><span class="icon-basic icon-check-circled" aria-hidden="true"></span><strong style="font-weight:normal;">' + message + '</strong></div></div>'
                    form.classList.remove('was-validated');
                }

                // Show the alert and revert the button state
                feedback.innerHTML = html;
                feedback.style.display = 'block';
                button.innerHTML = buttonValue;
                button.removeAttribute('disabled');

                if (response.data.clear) {
                    form.reset();
                }

                // If an internal error occurs, alert the user
            }).catch(function (error) {
                console.log(error); //log the error

                // Show the alert and revert the button state
                feedback.innerHTML = '<div class="alert alert--error alert-dismissible" role="alert"><button type="button" class="btn-close alert__dismiss" aria-label="Close"></button><div class="alert__heading"><span class="icon-basic icon-attention-triangle" aria-hidden="true"></span><strong style="font-weight:normal;">' + errorMessage + '</strong></div></div>';
                feedback.style.display = 'block';
                button.innerHTML = buttonValue;
                button.removeAttribute('disabled');
            });
        });
    });

    // Handle the dismissing
    document.addEventListener('click', function(e) {
        if (e.target.classList.contains('alert__dismiss')) {
            e.target.closest('form').classList.remove('was-validated');

            let inputFields = e.target.closest('form').querySelectorAll('.has-error');
            console.log(inputFields);
            for (const inputField of inputFields) {
                inputField.classList.remove('has-error');
            }

            e.target.closest('.feedback').style.display = 'none';
            e.target.closest('.feedback').querySelector('.alert').remove();
        }
    });

    return 'validate';

}
